import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";

import AgentRow from "./Components/AgentRow";
import NotFoundView from "./Components/NotFoundView/NotFoundView";
import useAgentList from "./Hooks/useAgentList";
import AgentEditPopup from "./AgentEditPopup";
import { useCallback, useState, useEffect } from "react";
import Spinner from "../../components/Core/Spinner/Spinner";

export default function AgentList() {
  const { data, loading, error } = useAgentList();
  const agents = data?.filter(
    (user) => user.Groups.includes("agent") || user.Groups.includes("user")
  );

  const [open, setOpen] = useState({ item: null, open: false });
  const openEditPopup = (item) => {
    setOpen({ item: item, open: true });
  };

  const callAction = async (action, payload, item) => {
    if (action === "delete") {
      // eslint-disable-next-line no-restricted-globals
      var confirmed = confirm("Please confirm to delete?");
      if (!confirmed) return;
    }
    if (action === "edit") {
    }
  };

  return (
    <>
      <AgentEditPopup popup={open} setPopup={setOpen} action={callAction} />

      {loading ? (
        <Spinner />
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {agents?.length === 0 ? (
              <NotFoundView />
            ) : (
              agents?.map((agent) => (
                <AgentRow
                  openPopup={() => openEditPopup(agent)}
                  key={agent.Id}
                  agent={agent}
                />
              ))
            )}
          </ul>
        </div>
      )}
    </>
  );
}
