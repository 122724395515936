import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";

import TaskRow from "./Components/TaskRow";
import NotFoundView from "./Components/NotFoundView/NotFoundView";
import { useCallback, useState, useEffect } from "react";
import useTaskList from "./Hooks/useTaskList";
import AgentListWidget from "../Agent/AgentListWidget";
import TaskEditPopup from "./TaskEditPopup";
import { TaskEdit } from "./Api/TaskClient";

import Spinner from "../../components/Core/Spinner/Spinner";

export default function TaskList() {
  const { data, setData, loading, error } = useTaskList();

  const [open, setOpen] = useState({ item: null, open: false });
  const openEditPopup = (item) => {
    setOpen({ item: item, open: true });
  };

  const callAction = async (action, payload, item) => {
    if (action === "delete") {
      // eslint-disable-next-line no-restricted-globals
      var confirmed = confirm("Please confirm to delete?");
      if (!confirmed) return;
      // ui delete
      var newList = data.filter((p) => p.Id !== item.Id);
      setData(newList);
      // db delete
      var newModel = { ...item, RecordState: 1 };
      TaskEdit(newModel);
      setOpen({ item: null, open: false });
    }

    if (action === "edit") {
      var newModel = item;
      console.log(newModel);
      // ui edit
      var newList = data.map((p) => (p.Id !== newModel.Id ? p : newModel));
      setData(newList);
      // db edit
      TaskEdit(newModel);
      setOpen({ item: null, open: false });
    }
  };

  return (
    <>
      <TaskEditPopup popup={open} setPopup={setOpen} action={callAction} />

      <AgentListWidget />

      <a
        type="button"
        href={`/tasks/create/`}
        className="my-4 py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      >
        Create
      </a>

      <div className="my-4">
        {loading ? (
          <Spinner />
        ) : (
          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {!data ? (
                <NotFoundView />
              ) : (
                data?.map((task) => (
                  <TaskRow
                    openPopup={() => openEditPopup(task)}
                    key={task.Id}
                    task={task}
                  />
                ))
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
