import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";

import useTaskEdit from "./Hooks/useTaskEdit";
import useAgentList from "./Hooks/useAgentList";
import useFormList from "./Hooks/useFormList";
import useLocationList from "./Hooks/useLocationList";
import Selector from "./Components/Selector";
import Select from "react-tailwindcss-select";

export default function TaskEdit() {
  const { agents, error } = useAgentList();
  const onlineAgents = agents?.filter(
    (user) =>
      user.Online &&
      (user.Groups.includes("agent") || user.Groups.includes("user"))
  );

  console.log(onlineAgents);

  const { forms } = useFormList();
  const { locations } = useLocationList();
  const states = [
    { Id: "1", Name: "Pending" },
    { Id: "2", Name: "In Progress" },
    { Id: "3", Name: "Completed" },
    { Id: "4", Name: "Cancelled" },
  ];

  /*const options = [
    { value: "fox", label: "🦊 Fox" },
    { value: "Butterfly", label: "🦋 Butterfly" },
    { value: "Honeybee", label: "🐝 Honeybee" }
  ];*/

  //------------------------

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const formValues = watch();
  const { taskEdit, loading } = useTaskEdit(
    formValues,
    agents,
    forms,
    locations,
    states
  );

  const showForm = formValues.formId?.includes("1"); // alarm report

  var options =
    locations?.map((loc) => ({ value: loc.id, label: loc.name })) ?? [];
  const [locaction, setLocation] = useState(null);
  const handleLocationChange = (loc) => {
    console.log("value:", loc);
    setLocation(loc);
    setValue("locationId", loc.value.toString());
    //formValues.locationId = loc.value;
  };

  return (
    <form onSubmit={handleSubmit(taskEdit)}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            New Task
          </h3>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                for="locationId"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Location
              </label>
              <div className="mt-2">
                <input
                  {...register("locationId", { required: true })}
                  name="locationId"
                  style={{ display: "none" }}
                  id="locationId"
                />
                <Select
                  isSearchable={true}
                  value={locaction}
                  onChange={handleLocationChange}
                  options={options}
                />
              </div>
            </div>

            {/* 
          <div className="sm:col-span-4">
            <label for="locationId"  className="block text-sm font-medium leading-6 text-gray-900">Location</label>
            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <select required {...register("locationId", { required: true })} name="locationId" id="locationId" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="" selected>Choose a location</option>
                    {locations?.map(location => <option value={location.id}>{location.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          */}

            <div className="sm:col-span-4">
              <label
                for="assigneeId"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Assignee
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <select
                    {...register("assigneeId")}
                    name="assigneeId"
                    id="assigneeId"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" selected>
                      Choose a assignee
                    </option>
                    {onlineAgents?.map((agent) => (
                      <option value={agent.Id}>{agent.Name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                for="stateId"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                State
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <select
                    required
                    {...register("stateId", { required: true })}
                    name="stateId"
                    id="stateId"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" selected>
                      Choose a state
                    </option>
                    {states?.map((state) => (
                      <option value={state.Id}>{state.Name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                for="formId"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Form
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <select
                    required
                    {...register("formId", { required: true })}
                    name="formId"
                    id="formId"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="" selected>
                      Choose a form
                    </option>
                    {forms?.map((form) => (
                      <option value={form.Id}>{form.Name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {showForm ? (
              <>
                <div className="sm:col-span-4">
                  <label
                    for="reportType"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Type
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        placeholder="Type"
                        required
                        {...register("reportType", { required: false })}
                        name="reportType"
                        id="reportType"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    for="reportZone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Zone
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        placeholder="Zone"
                        required
                        {...register("reportZone", { required: false })}
                        name="reportZone"
                        id="reportZone"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    for="reportOperator"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Operator
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        placeholder="Operator"
                        required
                        {...register("reportOperator", { required: false })}
                        name="reportOperator"
                        id="reportOperator"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    for="reportReceived"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Received
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="time"
                        placeholder="Received"
                        required
                        {...register("reportReceived", { required: false })}
                        name="reportReceived"
                        id="reportReceived"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-4">
                  <label
                    for="reportDispatched"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Dispatched
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        type="time"
                        placeholder="Dispatched"
                        required
                        {...register("reportDispatched", { required: false })}
                        name="reportDispatched"
                        id="reportDispatched"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-start gap-x-6">
        <a
          href="/tasks/"
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </a>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
