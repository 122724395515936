import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";

import { useCallback, useState, useEffect } from "react";
import AgentListWidget from "../Agent/AgentListWidget";
import { TaskEdit } from "./Api/TaskClient";

import NotFoundView from "./Components/NotFoundView";
import useItemList from "./Hooks/useItemList";
import ItemRow from "./Components/ItemRow";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import Spinner from "../../components/Core/Spinner/Spinner";

export default function ItemList() {
  var s = new Date();
  s.setHours(0, 0, 0, 0);

  var e = new Date();
  e.setHours(23, 59, 59, 999);

  const [state, setState] = useState([
    {
      startDate: s,
      endDate: e,
      key: "selection",
    },
  ]);

  const { data, setData, loading, error } = useItemList({ state });

  return (
    <>
      <DateRangePicker
        onChange={(item) => setState([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={state}
        direction="horizontal"
      />

      {loading ? (
        <Spinner />
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {!data ? (
              <NotFoundView />
            ) : (
              data?.map((item) => <ItemRow key={item.Id} item={item} />)
            )}
          </ul>
        </div>
      )}
    </>
  );
}
